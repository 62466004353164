@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: "Open Sans";
}

.container-shadow {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
}

svg.bi.bi-clock {
  font-size: 30px;
  margin-right: 5px;
  color: #0466C8;
}

.promotions_card {
  background: linear-gradient(106.99deg, rgba(248, 250, 252, 0.1) 1.07%, rgba(226, 232, 240, 1) 64.21%);
}

.providerCard {
  padding: 16px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
}

.calendarInput {
  border: 1px solid #CBD5E1;
  border-radius: 8px;
  height: 40px;
  width: calc(100% - 64px);
  padding-left: 16px;
  outline: none;
}

.appointmentHour {
  color: #334155;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #CBD5E1;
  border-radius: 32px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  cursor: pointer;
  user-select: none;
}

.paymentInfo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bookingStripeElement {
 width: 100%;
 border: 1px solid #e6e6e6;
 padding: 12px;
 border-radius: 5px;
 transition: 0.15s;
}

.StripeElement--focus {
  border: 1px solid hsla(210, 96%, 45%, 50%);
  box-shadow: 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)
}

.Clearent .input-group-addon, .Clearent .form-control {
  border-color: #D0D5DD !important;
}

.Clearent-submit.btn.btn-primary {
  border-radius: 30px;
  border: none;
}

.Clearent-footer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Clearent-title, .alert.alert-success, #Clearent-card-description-row {
  display: none;
}

.hideElement {
  display: none;
}

.Clearent-busy, .Clearent-busy-image {
  top: 0;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .paymentInfo-grid {
    grid-template-columns: 1fr;
  }
}


.custom-calendar  {
  bottom: 100%;
  z-index: 99;
  max-width: 408px;
  right: 0;
  margin-bottom: 10px;
  background: #fff;
  padding-bottom: 12px;
  border: 1px solid #F1F5F9;
  border-radius: 8px;
}

.custom-calendar .react-calendar  {
  border: none;
}

.custom-calendar button {
  flex: 1;
}
