.purple-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #7F56D9;
        color: white;
    }

    &.secondary-button {
        color: #7F56D9;
        background: #7F56D90D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #7F56D9;
    }

    &.stroke-primary {
        stroke:#7F56D9
    }

    &.fill-primary {
        fill:#7F56D9
    }

    &.card-active {
        border: 2px solid #7F56D9;
        background: #7F56D90D;
    }

    &.page-active {
        border-bottom: 1px solid #7F56D9;
        color: #7F56D9
    }
    &.secondary-bg {
        background: #7F56D90D;
    }
}

.teal-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #2ED3B7;
        color: white;
    }

    &.secondary-button {
        color: #2ED3B7;
        background: #2ED3B70D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #2ED3B7;
    }

    &.stroke-primary {
        stroke:#2ED3B7
    }

    &.fill-primary {
        fill:#2ED3B7
    }

    &.card-active {
        border: 1px solid #2ED3B7;
        background: #2ED3B70D;
    }

    &.page-active {
        border-bottom: 1px solid #2ED3B7;
        color: #2ED3B7
    }

    &.secondary-bg {
        background: #2ED3B70D;
    }
}

.blue-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #0B2559;
        color: white;
    }

    &.secondary-button {
        color: #0B2559;
        background: #0B25590D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #0B2559;
    }

    &.stroke-primary {
        stroke:#0B2559
    }

    &.fill-primary {
        fill:#0B2559
    }

    &.card-active {
        border: 1px solid #0B2559;
        background: #0B25590D;
    }

    &.page-active {
        border-bottom: 1px solid #0B2559;
        color: #0B2559
    }

    &.secondary-bg {
        background: #0B25590D;
    }
}

.rose-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #E34B79;
        color: white;
    }

    &.secondary-button {
        color: #E34B79;
        background: #E34B790D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #E34B79;
    }

    &.stroke-primary {
        stroke:#E34B79
    }

    &.fill-primary {
        fill:#E34B79
    }

    &.card-active {
        border: 1px solid #E34B79;
        background: #E34B790D;
    }

    &.page-active {
        border-bottom: 1px solid #E34B79;
        color: #E34B79
    }

    &.secondary-bg {
        background: #E34B790D;
    }
}

.grey-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #0F172A;
        color: white;
    }

    &.secondary-button {
        color: #0F172A;
        background: #0F172A0D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #0F172A;
    }

    &.stroke-primary {
        stroke:#0F172A
    }

    &.fill-primary {
        fill:#0F172A
    }

    &.card-active {
        border: 1px solid #0F172A;
        background: #0F172A0D;
    }

    &.page-active {
        border-bottom: 1px solid #0F172A;
        color: #0F172A;
    }
    &.secondary-bg {
        background: #0F172A0D;
    }
}

.pink-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #C11574;
        color: white;
    }

    &.secondary-button {
        color: #C11574;
        background: #C115740D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #C11574;
    }

    &.stroke-primary {
        stroke:#C11574;
    }

    &.fill-primary {
        fill:#C11574;
    }

    &.card-active {
        border: 1px solid #C11574;
        background: #C115740D;
    }

    &.page-active {
        border-bottom: 1px solid #C11574;
        color: #C11574
    }

    &.secondary-bg {
        background: #C115740D;
    }
}

.brown-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #331D0A;
        color: white;
    }

    &.secondary-button {
        color: #331D0A;
        background: #331D0A0D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #331D0A;
    }

    &.stroke-primary {
        stroke:#331D0A;
    }

    &.fill-primary {
        fill:#331D0A;
    }

    &.card-active {
        border: 1px solid #331D0A;
        background: #331D0A0D;
    }

    &.page-active {
        border-bottom: 1px solid #331D0A;
        color: #331D0A
    }

    &.secondary-bg {
        background: #331D0A0D;
    }
}

.cyan-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #088AB2;
        color: white;
    }

    &.secondary-button {
        color: #088AB2;
        background: #088AB20D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #088AB2;
    }

    &.stroke-primary {
        stroke:#088AB2;
    }

    &.fill-primary {
        fill:#088AB2;
    }

    &.card-active {
        border: 1px solid #088AB2;
        background: #088AB20D;
    }

    &.page-active {
        border-bottom: 1px solid #088AB2;
        color: #088AB2
    }

    &.secondary-bg {
        background: #088AB20D;
    }
}

.forest-theme {
    &.primary-button, &.react-calendar__tile--active:enabled:hover, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus, .Clearent-submit.btn.btn-primary {
        background: #3B945E;
        color: white;
    }

    &.secondary-button {
        color: #3B945E;
        background: #3B945E0D;
    }

    &.primary-color, .glyphicon.glyphicon-credit-card, .glyphicon.glyphicon-calendar, .glyphicon.glyphicon-lock {
        color: #3B945E;
    }

    &.stroke-primary {
        stroke:#3B945E;
    }

    &.fill-primary {
        fill:#3B945E;
    }

    &.card-active {
        border: 1px solid #3B945E;
        background: #3B945E0D;
    }

    &.page-active {
        border-bottom: 1px solid #3B945E;
        color: #3B945E
    }

    &.secondary-bg {
        background: #3B945E0D;
    }
}


